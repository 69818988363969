



















































































import { Component, Vue } from "vue-property-decorator";
import { email, required } from "@/helpers/rules";
import { LoginRequest } from "@/models/utils/login-request.interface";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
import { authenticate } from "@/services/api/authenticate.service";

@Component
export default class Login extends Vue {
  private valid = true;
  private showAlert = false;
  private alertText: string;
  private showPassword = false;
  private required = required;
  private email = email;

  private form: LoginRequest = {
    username: "",
    password: "",
  };

  private async login(): Promise<void> {
    overlayModule.showOverlay();
    this.showAlert = false;
    try {
      let r = await authenticate.login(this.form);
      authModule.login(r.data);
      this.$nextTick(() => this.$router.replace({ path: "/" }));
    } catch {
      overlayModule.hideOverlay();
      this.showAlert = true;
    }
  }
}
